import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import css from './Footer.scss';
import { getCountryConfig } from 'Helpers/config';
import withConfig from 'HOCs/withConfig/withConfig';
import Bottom from './Sections/Bottom.APP_TARGET';
import Top from './Sections/Top.APP_TARGET';
import { getFooterSections } from 'Selectors/seo';
import { configSelector } from 'Selectors/config';
import withRouter from 'HOCs/withRouter';
import { getLocaleZendeskUrl } from 'Selectors/config';
import AsyncDownloadApp from 'Components/DownloadApp/AsyncDownloadApp.APP_TARGET';
import ReactObserver from 'Components/ReactObserver/ReactObserver';
import { OLXAUTOS } from 'Constants/tracking';
import withTrack from 'HOCs/withTrack/withTrack';
import { FOOTER } from 'Constants/tracking';
import MgidComponent from 'Components/MgidComponent/MgidComponent';
import { isMobile } from 'Helpers/devices';

export const Footer = props => {
    const { configObj, brand, sections, intl, enableTopMobile, zendeskUrl, footerTitle, footerAddress, showDownloadApp, topSections, isOlxAutosLandingPage, track, onFooterClick, showMgid
    } = props;

    const trackFooterLink = (link, section) => {
        const { trackProps } = props;
        const params = {
            select_from: section,
            chosen_option: link.title,
            origin: props.origin,
            ...trackProps
        };

        props.track(OLXAUTOS.OLXAUTOS_LINKS_TAPPED, params);
    };

    const onView = useCallback(isInView => {
        if (isInView) {
            track(FOOTER.HP_FOOTER_IMPRESSION);
        }
    }, [track]);

    const country = getCountryConfig(configObj);

    return (
        <ReactObserver
            onChange={ onView }
        >
            { showDownloadApp && <AsyncDownloadApp /> }

            { showMgid && !isMobile && <div>
                <MgidComponent />
            </div>}

            <footer
                className={ css.footer }
                data-aut-id="footer"
                id="footer"
                onClick={ onFooterClick }
            >
                <Top
                    country={ country }
                    brand={ brand }
                    sections={ sections }
                    showTopMobile={ enableTopMobile }
                    zendeskUrl={ zendeskUrl }
                    topSections={ topSections }
                    isOlxAutosLandingPage={ isOlxAutosLandingPage }
                    { ...props }
                    // eslint-disable-next-line react/jsx-no-bind
                    track={ trackFooterLink }
                />
                { showMgid && isMobile && <div>
                    <MgidComponent />
                </div>}
                <Bottom country={ country } brand={ brand } sections={ sections } intl={ intl } zendeskUrl={ zendeskUrl } footerTitle={ footerTitle } footerAddress={ footerAddress } />
            </footer>

        </ReactObserver>
    );
};

Footer.propTypes = {
    configObj: PropTypes.object.isRequired,
    brand: PropTypes.string.isRequired,
    sections: PropTypes.array,
    enableTopMobile: PropTypes.bool,
    intl: PropTypes.shape({
        formatMessage: PropTypes.func.isRequired
    }).isRequired,
    zendeskUrl: PropTypes.string,
    footerTitle: PropTypes.string,
    footerAddress: PropTypes.string,
    showDownloadApp: PropTypes.bool,
    showMgid: PropTypes.bool,
    track: PropTypes.func.isRequired,
    origin: PropTypes.string,
    topSections: PropTypes.array,
    trackProps: PropTypes.object,
    isOlxAutosLandingPage: PropTypes.bool,
    isPaidCampaign: PropTypes.bool,
    isPPCMigration: PropTypes.bool,
    onFooterClick: PropTypes.func
};

Footer.defaultProps = {
    configObj: {},
    sections: [],
    enableTopMobile: false,
    footerTitle: '',
    footerAddress: '',
    showDownloadApp: false,
    showMgid: false,
    topSections: [],
    trackProps: {},
    isOlxAutosLandingPage: false,
    isPaidCampaign: false,
    onFooterClick: () => {}
};

export const mapStateToProps = (state, ownProps) => ({
    configObj: configSelector(state),
    brand: ownProps.config.get('theme', 'brand'),
    footerTitle: ownProps.config.get('footerTitle'),
    footerAddress: ownProps.config.get('footerAddress'),
    isPPCMigration: ownProps.config.get('ppcMigration'),
    sections: getFooterSections(state, { config: ownProps.config, sections: ownProps.sections }),
    zendeskUrl: getLocaleZendeskUrl(state, ownProps)
});

export default compose(
    withConfig,
    injectIntl,
    withRouter,
    withTrack,
    connect(mapStateToProps)
)(Footer);

